<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-7 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay lo mejor de dos mundos">Lo mejor de dos mundos</h2>
              <h3 class="title-2" itemprop="HandelBay en un solo lugar">en un solo lugar</h3>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es la solución">
                Markley HandelBay es una plataforma de compras empresariales (marketplace B2B) que conecta compradores
                potenciales con proveedores competitivos a través de una red social empresarial.
              </p>
            </div>
            <br>
            <div class="title-footer">
              <a :href="global.whatsapp" class="btn no-margin-left btn-handelbay-success btn-handelbay-black" target="_black" data-target="#modal-demo">Agendá tu demo ahora</a>
            </div>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="computers">
              <img src="../assets/images/landing/handelbay/handelbay_gestionintegralempresa.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-5 col-md-offset-1 col-sm-offset-0 col-xs-6 col-xs-offset-3 ">
          <div class="img_man">
            <img src="../assets/images/landing/handelbay/handelbay_gestionintegralempresa.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
        <div class="col-md-5 col-sm-7 text-right col-xs-12">
          <div class="handelbay-text" style="padding-bottom:18px;" itemscope itemtype="https://handelbay.com/">
            <h2 class="title"><b>Gestión integral de tu empresa</b></h2>
            <p class="text">
              Con Markley HandelBay podrás darte cuenta de todo lo que sucede en el área 
              comercial y en el área de compras, podés generar reportes por usuario,
              áreas, presupuesto, metas y mucho más. Nosotros queremos ayudar a gestionar 
              tu empresa de la mejor manera.
            </p>
            <p class="text">
              Aparecerás en Markley HandelBay como DUAL ante todas las empresas y podrás 
              realizar tus compras empresariales. Además podés darte a conocer a nuevos
              clientes y expandir tu mercado. ¡Markley HandelBay será tu mejor aliado en los negocios!
            </p>
            <h3 class="title space-title"><b>Múltiples usuarios y roles</b></h3>
            <p class="text">
              Manejá por separado tus asesores comerciales y tu departamento de
              compra en la misma herramienta. En HandelBay encontrarás diferentes
              roles y permisos que se adecuan a tu empresa.
            </p>
            <p class="text">
              Tenés a tu disposición perfiles: Cápitan, Teniente y Oficial, los cuáles pueden 
              ser comprador únicamente, proveedor únicamente o usuarios duales, quienes pueden comprar 
              y vender a la vez. Pero no te preocupes, es más fácil de lo que te imaginas: Markley HandelBay cambia los
              colores de la interfaz según el perfil: verde para comprador y azul para proveedor.
              Definitivamente queremos ayudarte en tu día a día.
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End ahorros en las compras -->
    <!-- Red de expansión -->
    <section id="expansion_network" class="dual_network">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
          <h2 class="title"><b>Seguridad en las negociaciones</b></h2>
          <p class="text" itemprop="HandelBay es la solución">
            En Markley HandelBay queremos que puedas hacer negocios 
            con seguridad y de manera confiable. Por eso,
            revisamos la documentación de todas las empresas (compradores y proveedores),
            y la llamamos "nivel 1".
            Ésta consiste en validar ante diferentes entidades estatales y privadas, la documentación 
            básica de las empresas.
          </p>
          <p class="text">
            Además en Markley HandelBay, exigimos actualizar la documentación periódicamente a todas las empresas, 
            de tal forma que esté disponible en línea 24/7 para que actualices tu base de datos.

          </p>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
        <div class="col-md-4 col-sm-4 text-right col-xs-12">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h3 class="title"><b>Compará cotizaciones y proveedores</b></h3>
            <p class="text" itemprop="HandelBay red empresarial">
              ¿Imaginás poder hacer un cuadro comparativo de los proveedores y sus cotizaciones?
              En Markley HandelBay lo podés hacer con solo un click. Además podés generar reportes de la 
              comparación para solicitar aprobaciones o dejar trazabilidad del proceso.
            </p>
            <p class="text">
              ¿Qué tal seria conocer el histórico de precios de todas las compras que realizás? 
              Con el histórico de precios de Markley HandelBay podrás tomar mejores desiciones a la hora de seleccionar 
              proveedores y comprar. 
              Todo en un sólo lugar.
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End Red de expansión -->
    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-sm-7 col-md-offset-1">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h2 class="title"><b>Portafolio completo en línea 24/7</b></h2>
            <p class="text" itemprop="HandelBay es la solución">
              Queremos que hagas más negocios y que des a conocer a todos 
              tu portafolio. En Markley HandelBay vas a poder tener tu portafolio en línea 24/7 
              para la red creciente de compradores potenciales.
              Las empresas podrán entrar a  tu perfil y ver los productos y servicios 
              que ofrecés, así como todos los archivos multimedia para darlos a conocer. Es 
              el equivalente a tener una tienda en línea en la cual te solicitan cotización antes de comprar.<br>
              <b class="info_title">¡Markley HandelBay es lo que estabas esperando para hacer crecer tu negocio!</b>
            </p>
            <h3 class="title space-title"><b>Tenés el 50% de descuento</b></h3>
            <p class="text">
              No lo dudes más. En Markley HandelBay no tenemos restricciones para tu combinación de planes, 
              podrías ser un proveedor en plan ELITE y un comprador con perfil BASIC.
              Arma el combo que más le funciona a tu empresa.
            </p>        
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_portafoliocompletoenlinea.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
    <div class="container">
      <div class="title-footer text-center">
        <h2 class="title">¿Estás listo para innovar en tu área de compras y comercial?</h2>
        <router-link to="/tarifas" class="btn btn-handelbay-dual">
          VER PLANES DE COMPRADOR
        </router-link>
        <a href="<%=tarifas_path%>" ></a>
      </div>
    </div>
    </section>
    <!-- End Listo para innovar -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Optimiza tus compras -->
    <section id="compare_quotes">
    <div class="container">   
      <div class="row">
        <div class="col-md-5 col-md-offset-1 col-sm-9 col-xs-6 col-xs-offset-3 col-sm-offset-0 text-left">
          <div class="brand">
            <img src="../assets/images/landing/handelbay/handelbay_unmundosinbeneficios.png" alt="Es hora de usar HandelBay en tu día a día">
          </div>
        </div> 
        <div class="col-md-5 col-sm-5 col-xs-12 text-left">
          <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
            <h2 class="title"><b>Un mundo de beneficios</b></h2>
            <p class="text" itemprop="HandelBay es la solución">
              Si sos comprador y vendedor obtené mejores condiciones
              comerciales en tus negociaciones y amplía tu portafolio 
              de clientes.            
            </p>
            <h3 class="title space-title"><b>Gestión integral</b></h3>
            <p class="text">
              Obtené trazabilidad en todos tus procesos de compras y ventas en un solo lugar.
            </p>
            <h4 class="title space-title"><b>Reportes en tiempo real</b></h4>
            <p class="text">            
              Controlá tu presupuesto y gestioná los resultados de tu equipo comercial en una sola 
              herramienta
            </p>        
            <h5 class="title space-title"><b>Navegá en la red</b></h5>  
            <p class="text">            
              La red social empresarial te permitirá ampliar tu universo de proveedores y buscar compradores que 
              tengan tu misma necesidad para realizar compras integradas y obtener mejores precios en el mercado.
            </p>  
          </div>
        </div>           
      </div>
      <!-- .row -->
      <br><br>
      <div class="row">
        <div class="col-md-10 col-sm-12 col-md-offset-1">        
          <div class="title-footer text-center">
            <a :href="global.whatsapp" class="btn btn-handelbay-dual" target="blnack" data-target="#modal-demo">AGENDÁ TU DEMO</a>
            <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
              REGISTRATE GRATIS AHORA
            </a>
          </div>
      </div>
      </div>
    </div>
    <!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <PorqueHandelbay />
    <ModalTuDemo />
    <ModalPlans />
  </div>
</template>
<script>
import global from '@/components/Global.vue'
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
import ModalPlans from "@/components/ModalPlans.vue"

export default {
  components:{
    PorqueHandelbay,
    ModalTuDemo,
    ModalPlans
  },
  data(){
    return{
      global: global
    }
  }
}
</script>
